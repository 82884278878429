import Footer from "../components/footer/footer";
import Header from "../components/header/header";
import "./home.scss";

export default function Home() {
  function listText(text: string) {
    return (
      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <div className="triangle" />
        <p className="section-2-text">{text}</p>
      </div>
    );
  }

  return (
    <>
      <Header />
      <div className="home-inner-body">
        <div style={{ height: "32px" }} />
        <div className="sections-container">
          <div className="text-section-1">
            <p className="section-1-title">
              Growth Hacking Accelerator
              <br />
              That Helps Thai Technology
              <br />
              Companies Scale
            </p>
            <div className="section-2-background circle1">
              <div className="text-section-2">
                <p className="section-2-title">Exclusive Access To:</p>
                {listText("Customers")}
                {listText("Corporate partners")}
                {listText("Investors")}
                {listText("Mentors")}
                {listText("Growth Hacking Support")}
                {listText("Fundraising Support")}
              </div>
            </div>
            <div>
              <p className="period-title">Application Period:</p>
              <p className="period-text">Sep - Nov 2022</p>
              <div style={{ height: "12px" }} />
              <p className="period-title">Program Period:</p>
              <p className="period-text">Jan - May 2023</p>
            </div>
          </div>
          <div className="section-2-background circle2">
            <div className="text-section-2">
              <p className="section-2-title">Exclusive Access To:</p>
              {listText("Customers")}
              {listText("Corporate partners")}
              {listText("Investors")}
              {listText("Mentors")}
              {listText("Growth Hacking Support")}
              {listText("Fundraising Support")}
            </div>
          </div>
        </div>
        <div style={{ height: "64px" }} />
        <div className="text-section-3">
          <span className="text-section-3-head">
            Thailand Accelerator is now accepting
          </span>{" "}
          • startups • mentors • corporate partners
        </div>
        <div
          className="button"
          onClick={() =>
            window.open(
              "https://techsauceteam.typeform.com/to/VdTbUc18",
              "_self"
            )
          }
        >
          Join Us
        </div>
        <div style={{ height: "32px" }} />
      </div>
      <div className="timeline-section">
        <p className="timeline-text">Program Timeline</p>
        <p className="comming-text">Comming Soon</p>
      </div>
      <Footer />
    </>
  );
}
