import "./header.scss";
import { ReactComponent as Logo } from "../../assets/logo.svg";

export default function Header() {
  return (
    <div className="header">
      <Logo width={125} height={40} />
      <div
        className="header-button"
        onClick={() =>
          window.open("https://techsauceteam.typeform.com/to/VdTbUc18", "_self")
        }
      >
        Join Us
      </div>
    </div>
  );
}
